import { CircularProgress, Container } from "@material-ui/core";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import "./App.css";
import FileViewer from "./file_viewer/FileViewer";
import styles from "./styles/viewer.module.css";
import useActionsListener from "./useActionsListener";
import useActivityListeners from "./useActivityListeners";

const theme = createTheme({
  overrides: {},
});

function App() {
  const [currentDocument, setCurrentDocument] = useState(null);

  function handleSetCurrentDocument(cDocument) {
    setCurrentDocument(cDocument);
  }

  const actionsListeners = useActionsListener({
    currentDocument,
    onSetCurrentDocument: handleSetCurrentDocument,
  });

  function sendActivityMessageToParent() {
    actionsListeners.sendMessageToParent({ topic: "iframe-activity" });
  }

  const activityListeners = useActivityListeners({
    onActivity: sendActivityMessageToParent,
  });

  useEffect(() => {
    activityListeners.enable();
    actionsListeners.enable();
    //Send ready message to parent so that it can send the document
    actionsListeners.sendMessageToParent({ topic: "Ready" });

    return () => {
      activityListeners.disable();
      actionsListeners.disable();
    };
  }, []);

  return (
    <div className="App">
      {!currentDocument && (
        <ThemeProvider theme={theme}>
          <Container className={styles.centeredProgress}>
            <CircularProgress />
          </Container>
        </ThemeProvider>
      )}
      {currentDocument && (
        <FileViewer
          fileContentType={currentDocument.metadata.contentType}
          fileData={currentDocument.dataBase64}
          fileType={currentDocument.dataType}
          handleBack={currentDocument.closeDocument}
          isEmptyDocument={currentDocument.emptyDocument}
          fhirDocument={currentDocument.fhirDocument}
        />
      )}
    </div>
  );
}

export default App;
