import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { ToggleOff } from "@material-ui/icons";
import React from "react";
import colors from "../../utils/colors";

const theme = createTheme({
  overrides: {
    MuiSvgIcon: {
      root: {
        color: colors.gray01,
        cursor: "pointer",
        height: "25px",
        marginRight: "100px",
        right: "0",
        top: "153px",
        position: "absolute",
        width: "25px",
      },
    },
  },
});

const XsltButton = ({ handleView, typeView }) => {
  const label = typeView ? "HTML" : "XSLT";
  return (
    <ThemeProvider theme={theme}>
      <ToggleOff onClick={handleView} />
    </ThemeProvider>
  );
};

export default XsltButton;
