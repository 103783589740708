import { useCallback, useEffect, useState } from "react";

const ACTIVITY_EVENTS = [
  "mousedown",
  "mousemove",
  "keydown",
  "scroll",
  "touchstart",
];

function useActivityListeners({ onActivity }) {
  const [enableActivitylisteners, setEnableActivitylisteners] = useState(false);

  const onActivityHandlerCallback = useCallback(onActivity, [onActivity]);

  function enableListeners() {
    setEnableActivitylisteners(true);
  }

  function disableListeners() {
    setEnableActivitylisteners(false);
  }

  function addListenersToActivityEvents() {
    ACTIVITY_EVENTS.forEach((eventName) => {
      window.addEventListener(eventName, onActivityHandlerCallback, true);
    });
  }

  function removeListenersToActivityEvents() {
    ACTIVITY_EVENTS.forEach((eventName) => {
      window.removeEventListener(eventName, onActivityHandlerCallback, true);
    });
  }

  useEffect(() => {
    if (enableActivitylisteners) {
      addListenersToActivityEvents();
    } else {
      removeListenersToActivityEvents();
    }

    return removeListenersToActivityEvents;
  }, [enableActivitylisteners, onActivityHandlerCallback]);

  return {
    enable: enableListeners,
    disable: disableListeners,
  };
}

export default useActivityListeners;
