import { useCallback } from "react";
import useWebMessageListener from "./useWebMessageListener";

function useActionsListener({ currentDocument, onSetCurrentDocument }) {
  function isOriginAllowed(originUrl) {
    //TODO validate against env var
    // const allowedOrigins = ["http://localhost:3000"];
    // return allowedOrigins.includes(originUrl);
    return true;
  }

  function createIframeNPrint(blobFile) {
    const iframePrintPdf = document.createElement("iframe");
    iframePrintPdf.style.visibility = "hidden";
    iframePrintPdf.src = window.URL.createObjectURL(blobFile);
    iframePrintPdf.name = "document-viewer-print-pdf";

    iframePrintPdf.onload = () => {
      const pdfFrame = window.frames["document-viewer-print-pdf"];
      pdfFrame.focus();
      pdfFrame.print();
    };

    document.body.appendChild(iframePrintPdf);

    // TODO: find a way to remove iframe after print
  }

  function base64toBlob(base64, type = "application/octet-stream") {
    return fetch(`data:${type};base64,${base64}`).then((res) => res.blob());
  }

  function printWithHiddenIframe(base64File) {
    base64toBlob(base64File, "application/pdf").then(createIframeNPrint);
  }

  function onWebMessageHandler(data) {
    if (!data.topic) {
      return;
    }

    if (data.topic === "RenderDocument" && data.document) {
      onSetCurrentDocument(data.document);
    } else if (
      data.topic === "document-viewer" &&
      data.action === "print-pdf"
    ) {
      printWithHiddenIframe(currentDocument.dataBase64);
    }
  }

  function enableListeners() {
    webMessageListener.enable();
  }

  function disableListeners() {
    webMessageListener.disable();
  }

  function onMessageHandler(e) {
    console.debug("DocumentViewer useActionsListener onMessageHandler", e);

    if (
      !isOriginAllowed(e.origin) ||
      !e.data ||
      typeof e.data.topic !== "string"
    ) {
      return;
    }

    onWebMessageHandler(e.data);
  }

  const onMessageHandlerCallback = useCallback(onMessageHandler, [
    currentDocument,
    onSetCurrentDocument,
  ]);

  const webMessageListener = useWebMessageListener({
    handleMessage: onMessageHandlerCallback,
  });

  function sendMessageToParent(message) {
    if (!window.parent) {
      console.debug("sendMessageToParent window.parent is not available");
      return;
    }

    webMessageListener.send(message, window.parent);
  }

  return {
    enable: enableListeners,
    disable: disableListeners,
    sendMessageToParent,
  };
}

export default useActionsListener;
