import { useCallback, useEffect, useState } from "react";

function useWebMessageListener({ handleMessage }) {
  const [enable, setEnable] = useState(false);

  function enableListener() {
    setEnable(true);
  }

  function disableListener() {
    setEnable(false);
  }

  const onMessageHandlerCallback = useCallback(handleMessage, [handleMessage]);

  function addMessageListenerForIframes() {
    window.addEventListener("message", onMessageHandlerCallback);
  }

  function removeMessageListenerForIframes() {
    window.removeEventListener("message", onMessageHandlerCallback);
  }

  function sendMessage(message, win) {
    if (!win) {
      return;
    }

    //Send ready message to iframe
    win.postMessage(message, "*");
  }

  useEffect(() => {
    if (enable) {
      addMessageListenerForIframes();
    } else {
      removeMessageListenerForIframes();
    }

    return removeMessageListenerForIframes;
  }, [enable, onMessageHandlerCallback]);

  return {
    enable: enableListener,
    disable: disableListener,
    send: sendMessage,
  };
}

export default useWebMessageListener;
