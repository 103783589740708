import React from "react";
import styles from '../../styles/xmlraw.module.css';

export function TextViewer({ text })
{
  return (
    <div className={styles.textViewer}>
      <textarea
        readOnly={true}
        value={text}
      />
    </div>
  );
}
