import React, { useEffect, useRef, useState } from "react";
import { XMLUtils } from "@nicheaim/utils-xml";
import { FetchResult } from "@nicheaim/types-generic";
import { SearchBox } from "./SearchBox";
import { highlightXPath } from "./HighlightXPath";
import {
  isInstanceOfXmlUrlOptions,
  XMLStringOptions,
  XMLUrlOptions
} from "@nicheaim/types-xml";
//import './xml-viewer-raw.scss';
import styles from '../../styles/xmlraw.module.css'

const uuid = require('uuid').v4;
const beautify = require('xml-beautifier');

export function XMLViewerRaw(options: XMLUrlOptions | XMLStringOptions)
{
  const viewerId: string = useRef(uuid()).current.toString();
  const [xmlString, setXmlString] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!loading) {
      return;
    }
    if (isInstanceOfXmlUrlOptions(options)) {
      fetchXMLString(options.xmlUrl).then(xmlStringResult => {
        if (xmlStringResult.value) {
          setXmlString(xmlStringResult.value);
          updateContent(xmlStringResult.value, viewerId);
        }
        else if (xmlStringResult.errorMessage) {
          updateContent(xmlStringResult.errorMessage, viewerId);
        }
      });
    }
    else {
      setXmlString(options.xmlString);
      updateContent(options.xmlString, viewerId);
    }
    setLoading(false);
  }, [options, viewerId, loading, xmlString]);

  return <div>
    {
      xmlString && (
        <SearchBox
          viewerId={viewerId}
          xmlString={xmlString}
          contentUpdateCallback={updateContent}
        />
      )
    }
    <div
      id={viewerId}
      //className="xml-viewer-raw"
      className={`xml-viewer-raw ${styles.xmlViewerRaw}`}
    />
  </div>;
}

function updateContent(xmlString: string, viewerId: string, xpath?: string): number
{
  let occurrences = 0;
  const contentElements = document.getElementsByClassName('xml-viewer-raw');
  //const contentElements = document.getElementsByClassName();
  let contentElement = null;
  if (contentElements) {
    contentElement = contentElements[0];
    if (xpath !== undefined) {
      occurrences = highlightXPath(xmlString, xpath, viewerId);
    }
    contentElement.appendChild(document.createTextNode(xmlString));
  }
  return occurrences;
}

function fetchXMLString(xmlUrl: string): Promise<FetchResult<string>>
{
  return new Promise(resolve => {
    XMLUtils.fetchString(xmlUrl).then(xmlStringResult => {
      if (xmlStringResult.value) {
        resolve({
          value: beautify(xmlStringResult.value)
        });
      }
      else {
        resolve({
          errorMessage: xmlStringResult.errorMessage
        });
      }
    });
  });
}
